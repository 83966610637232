<template>
    <v-select
        :items="categories"
        :disabled="disabled"
        label="Categoria"
        v-if="loaded"
        outlined
        v-model="currentIndex"
        item-text="Name"
        item-value="IdCategory"
        placeholder="Seleziona la categoria"
        :rules="[valueRules]"
    >
        <template v-slot:selection="{ item }">
            <img v-if="imagePath(item) !== null" :src="imagePath(item)" class="mx-2 d-block image-category" />
            {{
                item.Name
            }}
        </template>

        <template v-slot:item="{ item }">
            <img v-if="imagePath(item) !== null" :src="imagePath(item)" class="mx-2 d-block image-category" />
            {{
                item.Name
            }}
        </template>
    </v-select>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as promoCategoryActions from "../../store/actions/promoCategory";

export default {
    props: ["farmId", "model", "disabled"],

    data: () => ({
        valueRules: v => {
            if (parseInt(v) >= 0) return true;
            return "Valore Richiesto"
        }
    }),

    computed: {
        ...mapState({
            loaded: state => state.promoCategoryModule.loaded,
            categories: state => state.promoCategoryModule.categories
        }),

        currentIndex: {
            get() {
                return this.model;
            },
            set(newValue) {
                this.$emit("update:model", newValue);
            }
        }
    },

    methods: {
        ...mapActions({
            getCategories: promoCategoryActions.PROMOCATEGORY_GETCATEGORIES
        }),

        imagePath(item) {
            if (item.IconMD5 !== null) {
                return `/files/filestream/icoop_icons/${item.IconMD5}`;
            }
            return null;
        }
    },

    beforeMount() {
        // console.log(this.$route);
        console.log(this)
        this.getCategories(this.farmId);
    }
};
</script>

<style scoped>
.image-category {
    height: 38px;
    width: auto;
}
</style>
